import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Tyc = () => {
  return (
    <>
    <Header />
    <section className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <h1 className="text-center">Condiciones del Servicio</h1>
            <p className='my-5'><strong>Última actualización:</strong> 6 de noviembre de 2024</p>
            <p>Bienvenido a <strong className='text-aqua'>AiWeb</strong>. Al usar nuestra aplicación, aceptas cumplir con estos términos y condiciones. Te invitamos a leerlos cuidadosamente.</p>

            <h4 className="my-4">1. Uso de la aplicación</h4>
            <p>El usuario se compromete a no utilizar los servicios de AiWeb para fines ilegales o no autorizados.</p>
            <p>Eres responsable de la actividad en tu cuenta y de la seguridad de tus datos de acceso.</p>

            <h4 className="my-4">2. Servicios proporcionados</h4>
            <p>AiWeb proporciona un servicio de comunicación automatizada a través de WhatsApp, facilitando la gestión de mensajes.</p>
            <p>Nos reservamos el derecho de modificar o descontinuar el servicio en cualquier momento.</p>

            <h4 className="my-4">3. Limitación de responsabilidad</h4>
            <p>AiWeb no será responsable de daños o pérdidas derivadas del uso de la aplicación.</p>
            <p>El servicio se ofrece "tal cual", sin garantía de disponibilidad continua.</p>

            <h4 className="my-4">4. Cambios en los términos</h4>
            <p>Estos términos pueden ser modificados en cualquier momento. Los cambios entrarán en vigor inmediatamente después de ser publicados en el sitio web de AiWeb o en la aplicación.</p>

            <h4 className="my-4">5. Contacto</h4>
            <p>Para consultas sobre estos términos, puedes contactarnos en erick@aiweb.com.co.</p>


          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
  )
}

export default Tyc