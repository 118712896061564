import { gsap } from 'gsap'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {

  const handleScroll = target =>{
    if (target === null || target === undefined) return
    let reference = document.getElementById(target)
    window.scrollTo(0, (reference.offsetTop - 80))
  }

  const tl = gsap.timeline()

  useEffect(()=>{
    tl.to('.floatMobile', {y: 0, delay: 1})
  })

  return (
    <>
      <footer className='footer'>
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center text-center text-md-start">
              <p className="m-0">Copyright 2024. Todos los derechos reservados por </p>
              <p className="m-0">AI WEB (+57) 305 926 0031 | DESDE QUINDÍO Y COLOMBIA PARA TODO EL MUNDO </p>

              <div className="linksFooter">
                <Link to="/politica-de-privacidad" target="_blank">Política de privacidad</Link>
                <Link to="/condiciones-del-servicio" target="_blank">Condiciones del servicio</Link>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="d-flex align-items-center justify-content-center justify-content-md-end">
                <span>Síguenos en</span>
                <a href="#" className='in'  target='_blank'/>
                {/* <a href="#" className='fb'  target='_blank'/> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="floatMobile">
        <button onClick={()=> handleScroll('planweb')} className='plan'>Planes</button>
        <button onClick={()=> handleScroll('portfolio')} className='portfolio'>Portafolio</button>
        <a href="https://api.whatsapp.com/send?phone=573059260031&text=hola,%20necesito%20una%20asesor%C3%ADa" target='_blank' className='whatsapp'>Escríbenos</a>
      </footer>
      <div className="floatWsp">
        <span>Habla con un asesor</span>
        <a href="https://api.whatsapp.com/send?phone=573059260031&text=hola,%20necesito%20una%20asesor%C3%ADa" target='_blank'/>
      </div>

    </>
  )
}

export default Footer