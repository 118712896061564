import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const DataPolicy = () => {
  return (
    <>
    <Header />
    <section className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <h1 className="text-center">Política de Privacidad</h1>
            <p className='my-5'><strong>Última actualización:</strong> 6 de noviembre de 2024</p>
            <p>En <span className="text-aqua">AiWeb</span>, valoramos y respetamos tu privacidad. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos tu información cuando interactúas con nuestra aplicación.</p>

            <h4 className="my-4">1. Información que recopilamos</h4>
            <p><span className="text-aqua ">Información de cuenta:</span> Recopilamos tu número de teléfono y otros datos de perfil necesarios para ofrecer los servicios de la aplicación.</p>
            <p><span className="text-aqua ">Datos de uso:</span> Registramos información sobre cómo usas la aplicación, lo cual nos permite mejorar tu experiencia.</p>

            <h4 className="my-4">2. Cómo usamos tu información</h4>
            <p>Utilizamos los datos recopilados para facilitar la comunicación automatizada en WhatsApp y para brindarte asistencia.</p>
            <p>La información también puede ser usada para análisis internos, con el fin de mejorar nuestros servicios.</p>



            <h4 className="my-4">3. Con quién compartimos tu información</h4>
            <p>No compartimos tu información con terceros, excepto cuando sea necesario para cumplir con requisitos legales o si resulta indispensable para el funcionamiento de la aplicación.</p>

            <h4 className="my-4">4. Seguridad de tus datos</h4>
            <p>Aplicamos medidas de seguridad para proteger tus datos personales frente a accesos no autorizados y pérdidas.</p>

            <h4 className="my-4">5. Cambios en esta Política de Privacidad</h4>
            <p><span className="text-aqua ">AiWeb</span> puede actualizar esta política periódicamente. Te notificaremos cualquier cambio relevante a través de la aplicación o por correo electrónico.</p>


            <h4 className="my-4">6. Contacto</h4>
            <p>Si tienes alguna pregunta sobre esta política, contáctanos en erick@aiweb.com.co.</p>


          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
  )
}

export default DataPolicy